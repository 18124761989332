import React from "react";
import { FlatList, Text, View } from "react-native";
import dayjs from "dayjs";

import CalendarDay from "./CalendarDay";

dayjs.extend(require("dayjs/plugin/isoWeek"));
dayjs.extend(require("dayjs/plugin/isToday"));

export default function CalendarView({
  dayProps,
  lang,
  loadMoreData,
  refreshData,
  triggerReRenderData,
  onSelectSession,
  refreshing,
  dayData,
  sessionsProps,
  todayProps,
  userData,
  wrapperStyle,
}) {
  return (
    <View style={wrapperStyle}>
      <FlatList
        data={dayData}
        extraData={triggerReRenderData}
        initialNumToRender={1}
        maxToRenderPerBatch={1}
        onEndReachedThreshold={0.8}
        onEndReached={loadMoreData}
        onRefresh={refreshData}
        refreshing={refreshing}
        showsHorizontalScrollIndicator={false}
        showsVerticalScrollIndicator={false}
        updateCellsBatchingPeriod={50}
        renderItem={({ item }) => {
          const currentDay = dayjs(item.date, "YYMMDD");
          return (
            <CalendarDay
              scheduling={true}
              date={currentDay}
              dayProps={{
                ...dayProps,
                ...(currentDay.isToday() ? todayProps : null),
              }}
              key={item.date}
              lang={lang}
              onSelectSession={onSelectSession}
              dayData={item}
              sessionsProps={sessionsProps}
              userData={userData}
            />
          );
        }}
        keyExtractor={(item) => {
          return item.date;
        }}
      />
    </View>
  );
}

/*



*/
