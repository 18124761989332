import React from "react";

// components
import { Pressable, Text, View } from "react-native";
import Session2 from "./Session2";

export default function DayLocalSessions({
  court,
  isPlayersEditor,
  lang,
  onSelectSession,
  sessionProps,
  sessions,
  slots = [],
  userData,
}) {
  return (
    <View style={{ flex: 1 }}>
      <Text {...sessionProps.courtProps}>
        {court && court !== "_" ? `(${court})` : ""}
      </Text>
      {slots.map((key, index) => {
        if (!sessions[key])
          return <View key={index} style={sessionProps.emptySlotStyle}></View>;
        else
          return (
            <Pressable
              key={index}
              onPress={() => {
                onSelectSession(sessions[key]);
              }}
              style={{ flex: 1 }}
            >
              <Session2
                isPlayersEditor={isPlayersEditor}
                lang={lang}
                session={sessions[key]}
                sessionProps={sessionProps}
                userData={userData}
              />
            </Pressable>
          );
      })}
    </View>
  );
}
