// Librairies
import React from "react";

// components
import { View, Text } from "react-native";
import PlayersList from "./PlayersList";

const Session3 = ({
  isPlayersEditor,
  playersWidth,
  session,
  sessionProps,
  userData,
}) => {
  if (!userData.details || !userData.UIdata) return null;
  return (
    <View
      style={[
        sessionProps.containerStyle,
        !session.mayAdd ? sessionProps.sessionFull : null,
        session.isPast ? sessionProps.pastSessionStyle : null,
        session.cancelled ? sessionProps.cancelledSession : null,
      ]}
    >
      <View
        style={{
          flexDirection: "row",
          alignItems: "center",
        }}
      >
        <Text {...sessionProps.timeProps}>{`${session.formattedTime} - `}</Text>
        <Text {...sessionProps.sessionTypeProps}>
          {session.formattedSessionType}
        </Text>
      </View>

      <View
        style={{
          flexDirection: "row",
          alignItems: "center",
          height: 22,
        }}
      >
        <View style={{ flex: 1, flexDirection: "row" }}>
          {session.age_group || session.category ? (
            <View
              style={[
                sessionProps.ageGroup.wrapperStyle,
                {
                  backgroundColor:
                    session.ui_category.color ||
                    sessionProps.ageGroup.wrapperStyle.defaultBackgroundColor,
                  flex: -1,
                },
              ]}
            >
              <Text {...sessionProps.ageGroup.textProps}>
                {`${session.ui_age_group.short_label || "_"} / ${
                  session.ui_category.short_label || "_"
                }`}
              </Text>
            </View>
          ) : null}
        </View>
      </View>

      <View
        style={{
          flexDirection: "row",
          height: 32,
          width: "100%",
          marginTop: 3,
        }}
      >
        <PlayersList
          avatarProps={sessionProps.avatarProps}
          avatarSize={30}
          isPlayersEditor={isPlayersEditor}
          fitInParentWidth={playersWidth}
          maxNumPlayers={session.max_players}
          players={session.players}
          session={session}
          userData={userData}
          wrapperStyle={sessionProps.playersWrapper}
        />
      </View>
      <View style={{ width: "100%" }}>
        <Text {...sessionProps.message}>{session.message}</Text>
      </View>
    </View>
  );
};
export default Session3;
