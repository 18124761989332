// Librairies
import React, { useEffect, useState } from "react";

// components
import { View, Text } from "react-native";
import PlayersList from "./PlayersList";

// API
import sessionsAPI from "../../../data/sessions";

const Session2 = ({
  isPlayersEditor,
  lang,
  session,
  sessionProps,
  userData,
}) => {
  const [message, setMessage] = useState("");
  const [mayAdd, setMayAdd] = useState(false);
  const [playersWidth, setPlayersWidth] = useState(null);

  useEffect(() => {
    const spots_left = session?.max_players - (session.players || []).length;
    if (
      !session.date ||
      !session.time ||
      session.cancelled ||
      sessionsAPI.isPast(session.date + session.time)
    ) {
      setMessage(lang.CLOSED_SESSION);
      setMayAdd(false);
    } else if (session.max_players && spots_left > 0) {
      if (spots_left == 1) setMessage(lang.ONE_SPOT_LEFT);
      else setMessage(spots_left + lang.SEVERAL_SPOT_LEFT);
      setMayAdd(true);
    } else {
      setMessage(lang.NO_SPOT_LEFT);
      setMayAdd(false);
    }
  }, [session]);

  if (!userData.details || !userData.UIdata) return null;
  return (
    <View
      style={[
        sessionProps.containerStyle,
        !mayAdd ? sessionProps.sessionFull : null,
        sessionsAPI.isPast(session.date + session.time)
          ? sessionProps.pastSessionStyle
          : null,
        session.cancelled ? sessionProps.cancelledSession : null,
      ]}
    >
      <View
        style={{
          flexDirection: "row",
          alignItems: "center",
        }}
      >
        <Text {...sessionProps.timeProps}>
          {`${sessionsAPI.timeBackToFront(session.time)} - `}
        </Text>
        <Text {...sessionProps.sessionTypeProps}>
          {userData.UIdata?.session_types[session.session_type]?.short_label ||
            session.session_type}
        </Text>
      </View>

      <View
        style={{
          flexDirection: "row",
          alignItems: "center",
          height: 22,
        }}
      >
        <View style={{ flex: 1, flexDirection: "row" }}>
          {session.age_group || session.category ? (
            <View
              style={[
                sessionProps.ageGroup.wrapperStyle,
                {
                  backgroundColor:
                    (
                      userData.UIdata["categories"].find(
                        (el) => el.label === session.category
                      ) || {}
                    ).color ||
                    sessionProps.ageGroup.wrapperStyle.defaultBackgroundColor,
                  flex: -1,
                },
              ]}
            >
              <Text {...sessionProps.ageGroup.textProps}>
                {`${
                  (
                    userData.UIdata["age_groups"].find(
                      (el) => el.label === session.age_group
                    ) || {}
                  ).short_label || "_"
                } / ${
                  (
                    userData.UIdata["categories"].find(
                      (el) => el.label === session.category
                    ) || {}
                  ).short_label || "_"
                }`}
              </Text>
            </View>
          ) : null}
        </View>
      </View>

      <View
        onLayout={(event) => setPlayersWidth(event.nativeEvent?.layout.width)}
        style={{
          flexDirection: "row",
          height: 32,
          width: "100%",
          marginTop: 3,
        }}
      >
        <PlayersList
          avatarProps={sessionProps.avatarProps}
          avatarSize={30}
          isPlayersEditor={isPlayersEditor}
          fitInParentWidth={playersWidth}
          maxNumPlayers={session.max_players}
          players={session.players}
          session={session}
          userData={userData}
          wrapperStyle={sessionProps.playersWrapper}
        />
      </View>
      <View style={{ width: "100%" }}>
        <Text {...sessionProps.message}>{message}</Text>
      </View>
    </View>
  );
};
export default Session2;
