import React, { useEffect, useState } from "react";
import dayjs from "dayjs";

// components
import { Pressable, View, Text } from "react-native";
import Session3 from "./Session3";
import PlayersList from "./PlayersList";

dayjs.extend(require("dayjs/plugin/isoWeek"));

export default function CalendarDay({
  date,
  dayProps = {},
  isPlayersEditor,
  lang,
  onSelectSession,
  dayData,
  sessionsProps,
  userData,
}) {
  const [playersWidth, setPlayersWidth] = useState(0);

  if (!dayData?.slots) return null;
  return (
    <>
      <View {...dayProps.container}>
        <Text style={dayProps.textStyle}>
          {date.format("dddd D MMMM YYYY")}
        </Text>
      </View>
      {!dayData.sessions ? null : (
        <View
          style={{
            flex: 1,
            flexDirection: "row",
            marginBottom: 2,
          }}
        >
          {dayData.sortedSites.map(({ location, courts }, locationIndex) => (
            <View
              style={[sessionsProps.containerStyle, { flex: courts.length }]}
              key={locationIndex}
            >
              <Text {...sessionsProps.locationProps}>{location}</Text>
              <View style={{ flex: 1, flexDirection: "row" }}>
                {courts.map((court, courtIndex) => (
                  <View
                    style={{ flex: 1 }}
                    key={courtIndex}
                    onLayout={(event) => {
                      if (!playersWidth)
                        setPlayersWidth(event.nativeEvent?.layout.width - 12);
                    }}
                  >
                    <Text {...sessionsProps.session2.courtProps}>
                      {court && court !== "_" ? `(${court})` : ""}
                    </Text>
                    {dayData.slots.map((time, index) => {
                      if (!dayData.sessions[location][court][time])
                        return (
                          <View
                            key={index}
                            style={sessionsProps.session2.emptySlotStyle}
                          ></View>
                        );
                      else
                        return (
                          <Pressable
                            key={index}
                            onPress={() => {
                              onSelectSession(
                                dayData.sessions[location][court][time]
                              );
                            }}
                            style={{ flex: 1 }}
                          >
                            <Session3
                              isPlayersEditor={isPlayersEditor}
                              playersWidth={playersWidth}
                              session={dayData.sessions[location][court][time]}
                              sessionProps={sessionsProps.session2}
                              userData={userData}
                            />
                          </Pressable>
                        );
                    })}
                  </View>
                ))}
              </View>
            </View>
          ))}
        </View>
      )}
    </>
  );
}

/*

<DayLocalSessions
                    court={court}
                    key={court}
                    scheduling={scheduling}
                    isPlayersEditor={isPlayersEditor}
                    lang={lang}
                    onSessionCopy={onSessionCopy}
                    onSelectSession={onSelectSession}
                    sessionProps={sessionsProps.session2}
                    sessions={dayData.sessions[location][court]}
                    slots={dayData.slots}
                    userData={userData}
                  />


<DayLocalContainer
              key={location}
              scheduling={scheduling}
              isPlayersEditor={isPlayersEditor}
              lang={lang}
              location={location}
              onSelectSession={onSelectSession}
              onSessionCopy={onSessionCopy}
              sessions={dayData.sessions[location]}
              sessionsProps={sessionsProps}
              slots={dayData.slots}
              userData={userData}
            />



Object.keys(dayData.sessions)
            .sort((first, second) =>
              first.toUpperCase() > second.toUpperCase() ? 1 : -1
            )
{Object.entries(sessions).sort().map(([location, localSessions]) => (
            <DayLocalContainer
              key={location}
              scheduling={scheduling}
              isSessionEditor={isSessionEditor}
              isPlayersEditor={isPlayersEditor}
              location={location}
              onSelectSession={onSelectSession}
              onSessionCopy={onSessionCopy}
              sessions={localSessions}
              sessionsProps={sessionsProps}
              slots={slots}
            />
          ))}

*/
