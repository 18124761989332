// Libraries
import React, { useCallback, useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigation } from "@react-navigation/native";
import dayjs from "dayjs";
dayjs.extend(require("dayjs/plugin/isoWeek"));
dayjs.extend(require("dayjs/plugin/customParseFormat"));

// UI components
import { Text, View } from "react-native";
import { MonthSelector } from "../../components";
import MonthViewAgenda from "./components/MonthViewAgenda";
import Loading from "../Loading";

// App data
import routes from "../../navigation/routes";
import { LanguageContext, StylesContext } from "../../data/contexts";

// API
import sessionsAPI from "../../data/sessions";

// Component: Agenda
export default function Agenda() {
  const [month, setMonth] = useState(null);
  const [loading, setLoading] = useState(true);
  const [filter, setFilter] = useState({});
  const [preparedSessions, setPreparedSessions] = useState(null);
  const navigation = useNavigation();
  const sessions = useSelector((state) => {
    return (state.sessions || {})[month?.format("YYYYMM")];
  });
  const userData = useSelector((state) => state.user);
  const lang = useContext(LanguageContext);
  const { Agenda: props } = useContext(StylesContext);

  useEffect(() => {
    setPreparedSessions(
      sessionsAPI.getFilteredMonthSessions(sessions, filter, month, false)
    );
  }, [month, filter, sessions]);

  useEffect(() => {
    const user = userData.details;
    // initialize filters
    if (user) {
      const tribeNamesList = [user.uid];
      if (user.dependants)
        user.dependants.forEach((member) => {
          tribeNamesList.push(member.uid);
        });
      setFilter({
        ...filter,
        users: [...tribeNamesList],
      });
    }
  }, [userData]);

  const loadMonth = useCallback((month) => {
    sessionsAPI
      .loadMonthSessions(month)
      .then(() => {
        setLoading(false);
        setMonth(month);
      })
      .catch((e) => {
        console.warn("Loading month data failed unexpectedly. ", e);
        setLoading(false);
      });
  }, []);

  const onSelectSession = useCallback((session) => {
    sessionsAPI.loadSessionData(session.date);
    navigation.navigate(routes.SESSION_DETAILS, {
      data: { ...session },
      isNew: false,
    });
  }, []);

  // Rendering
  if (
    !userData ||
    !userData.details ||
    !userData.avatars ||
    !userData.UIdata ||
    !userData.rules ||
    !lang
  ) {
    return <Loading />;
  } else {
    return (
      <>
        <View style={{ flex: 1 }}>
          <View style={props.headingBar.container}>
            <Text style={props.headingBar.textStyle}>{lang.YOUR_BOOKINGS}</Text>
          </View>
          <View style={{ flex: 1 }}>
            <View style={props.selectMonthBarStyle}>
              <MonthSelector
                containerProps={props.selectMonth.container}
                leftButtonProps={props.selectMonth.leftButton}
                monthList={lang.MONTHS_LONG}
                onChange={(selectedMonth) => {
                  setLoading(true);
                  loadMonth(selectedMonth);
                }}
                rightButtonProps={props.selectMonth.rightButton}
                titleProps={props.selectMonth.monthYearText}
              />
            </View>
            {loading ? (
              <Loading />
            ) : (
              <View style={{ flex: 1 }}>
                <MonthViewAgenda
                  scheduling={false}
                  isSessionEditor={userData.rules.ROLE_TO_EDIT_SESSION}
                  isPlayersEditor={userData.rules.ROLE_TO_EDIT_PLAYER}
                  lang={lang}
                  month={month}
                  noBookingText={lang.NO_BOOKING}
                  noBookingStyle={props.noBooking}
                  onSelectSession={onSelectSession}
                  sessionsProps={props.sessions}
                  sessions={preparedSessions}
                  userData={userData}
                  weekLocalized={lang.WEEK}
                  {...props.monthView}
                />
              </View>
            )}
          </View>
        </View>
      </>
    );
  }
}
