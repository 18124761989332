import React, { useEffect, useState } from "react";
import dayjs from "dayjs";

// components
import { View, Text } from "react-native";
import {
  Menu,
  MenuOptions,
  MenuOption,
  MenuTrigger,
} from "react-native-popup-menu";
import DayLocalContainer from "./DayLocalContainer";

dayjs.extend(require("dayjs/plugin/isoWeek"));

export default function DayView({
  date,
  scheduling,
  clipboard,
  isSessionEditor,
  dayProps = {},
  isPlayersEditor,
  lang,
  onNewSession,
  onDayCopy,
  onPaste,
  onSelectSession,
  onSessionCopy,
  sessions,
  sessionsProps,
  userData,
}) {
  const [slots, setSlots] = useState(null);

  useEffect(() => {
    const newSlots = new Set();
    for (var sessionsInLocation in sessions) {
      for (var sessionsInCourts in sessions[sessionsInLocation]) {
        for (var sessionHour in sessions[sessionsInLocation][
          sessionsInCourts
        ]) {
          newSlots.add(sessionHour);
        }
      }
    }
    setSlots(Array.from(newSlots).sort());
  }, [sessions]);

  if (!slots) return null;
  return (
    <>
      <View {...dayProps.container}>
        {isSessionEditor ? (
          <Menu>
            <MenuTrigger>
              <Text style={dayProps.textStyle}>{date.format("dddd D")}</Text>
            </MenuTrigger>
            <MenuOptions
              customStyles={{
                optionsContainer: {
                  borderRadius: 6,
                  borderWidth: 1,
                  borderColor: "white",
                },
                optionWrapper: {
                  alignContent: "center",
                  alignItems: "center",
                  justifyContent: "center",
                  borderColor: "rgba(0, 0, 0, 0.3)",
                  borderBottomWidth: 1,
                  marginHorizontal: 3,
                  paddingVertical: 6,
                },
              }}
            >
              <MenuOption
                customStyles={{
                  optionWrapper: {
                    alignItems: "center",
                    backgroundColor: "#F2CB05",
                    borderTopLeftRadius: 6,
                    borderTopRightRadius: 6,
                  },
                  optionText: { color: "white" },
                }}
                disabled
                text={date.format("dddd D MMMM YYYY")}
              />
              {sessions ? (
                <MenuOption
                  text="copy"
                  onSelect={() => onDayCopy(date, sessions)}
                />
              ) : null}
              {!clipboard.content ||
              clipboard.type == "week" ||
              (clipboard.type == "day" &&
                clipboard.sessionDate == date) ? null : (
                <MenuOption text="paste" onSelect={() => onPaste(date)} />
              )}

              <MenuOption
                text="new session"
                onSelect={() => onNewSession(date)}
              />
            </MenuOptions>
          </Menu>
        ) : (
          <Text style={dayProps.textStyle}>{date.format("dddd D MMMM YYYY")}</Text>
        )}
      </View>
      {!sessions ? null : (
        <View
          style={{
            flex: 1,
            flexDirection: "row",
            marginBottom: 2,
          }}
        >
          {Object.keys(sessions)
            .sort((first, second) =>
              first.toUpperCase() > second.toUpperCase() ? 1 : -1
            )
            .map((location) => (
              <DayLocalContainer
                key={location}
                scheduling={scheduling}
                isSessionEditor={isSessionEditor}
                isPlayersEditor={isPlayersEditor}
                lang={lang}
                location={location}
                onSelectSession={onSelectSession}
                onSessionCopy={onSessionCopy}
                sessions={sessions[location]}
                sessionsProps={sessionsProps}
                slots={slots}
                userData={userData}
              />
            ))}
        </View>
      )}
    </>
  );
}

/*
{Object.entries(sessions).sort().map(([location, localSessions]) => (
            <DayLocalContainer
              key={location}
              scheduling={scheduling}
              isSessionEditor={isSessionEditor}
              isPlayersEditor={isPlayersEditor}
              location={location}
              onSelectSession={onSelectSession}
              onSessionCopy={onSessionCopy}
              sessions={localSessions}
              sessionsProps={sessionsProps}
              slots={slots}
            />
          ))}

*/
