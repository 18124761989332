import React from "react";
import { FlatList, Text, View } from "react-native";
import dayjs from "dayjs";

import DayView from "./DayView";

dayjs.extend(require("dayjs/plugin/isoWeek"));
dayjs.extend(require("dayjs/plugin/isToday"));

export default function MonthViewAgenda({
  dayProps,
  lang,
  noBookingText,
  noBookingStyle,
  onSelectSession,
  sessions = [],
  sessionsProps,
  todayProps,
  userData,
  wrapperStyle,
}) {
  return (
    <View style={wrapperStyle}>
      {sessions.length < 1 ? (
        <Text style={noBookingStyle}>{noBookingText}</Text>
      ) : null}
      <FlatList
        data={sessions}
        initialNumToRender={2}
        maxToRenderPerBatch={1}
        showsHorizontalScrollIndicator={false}
        showsVerticalScrollIndicator={false}
        updateCellsBatchingPeriod={50}
        windowSize={32}
        renderItem={({ item }) => {
          const currentDay = dayjs(item.date, "YYMMDD");
          return (
            <DayView
              date={currentDay}
              dayProps={{
                ...dayProps,
                ...(currentDay.isToday() ? todayProps : null),
              }}
              key={item.date}
              lang={lang}
              onSelectSession={onSelectSession}
              sessions={item.data}
              sessionsProps={sessionsProps}
              userData={userData}
            />
          );
        }}
        keyExtractor={(item) => item.date}
      />
    </View>
  );
}
