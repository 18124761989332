import React from "react";
import { View, Text } from "react-native";
import DayLocalSessions from "./DayLocalSessions";

export default function DayLocalContainer({
  scheduling,
  isPlayersEditor,
  lang,
  location,
  onSessionCopy,
  onSelectSession,
  sessions,
  sessionsProps,
  slots,
  userData,
}) {
  return (
    <View
      style={[
        sessionsProps.containerStyle,
        { flex: Object.keys(sessions).length },
      ]}
    >
      <Text {...sessionsProps.locationProps}>{location}</Text>
      <View style={{ flex: 1, flexDirection: "row" }}>
        {Object.keys(sessions)
          .sort((first, second) =>
            first.toUpperCase() > second.toUpperCase() ? 1 : -1
          )
          .map((court) => (
            <DayLocalSessions
              court={court}
              key={court}
              scheduling={scheduling}
              isPlayersEditor={isPlayersEditor}
              lang={lang}
              onSessionCopy={onSessionCopy}
              onSelectSession={onSelectSession}
              sessionProps={sessionsProps.session2}
              sessions={sessions[court]}
              slots={slots}
              userData={userData}
            />
          ))}
      </View>
    </View>
  );
}

/*
{Object.entries(sessions).sort().map(([court, timeSession]) => (
          <DayLocalSessions
            court={court}
            key={court}
            scheduling={scheduling}
            isPlayersEditor={isPlayersEditor}
            onSessionCopy={onSessionCopy}
            onSelectSession={onSelectSession}
            sessionProps={sessionsProps.session}
            sessions={timeSession}
            slots={slots}
          />
        ))}

*/
