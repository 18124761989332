export default ({
  animations,
  button,
  colors,
  deleteIcon,
  iconButton,
  input,
  locationIcon,
  miniAvatar,
  picker,
  pickerDisabled,
  shadows,
  swipeLeftIcon,
  typos,
}) => ({
  animations,
  input,
  miniAvatar,
  picker: {
    ...picker,
    containerStyle: { ...picker.containerStyle, paddingHorizontal: 0 },
    labelContainerStyle: { ...picker.labelContainerStyle, marginBottom: 0 },
    labelStyle: {
      ...picker.labelStyle,
      borderColor: colors.transparent,
      color: colors.primary,
    },
  },
  pickerDisabled,

  bookSaveButton: {
    ...button,
    containerStyle: {
      ...button.containerStyle,
      marginBottom: 20,
      marginTop: 16,
      marginHorizontal: 24,
    },
  },

  cancelSessionButton: {
    ...button,
    containerStyle: {
      ...button.containerStyle,
      marginBottom: 20,
      marginTop: 16,
      marginHorizontal: 24,
    },
    inactiveBackgroundColor: colors.error,
  },

  container: {
    backgroundColor: colors.background,
    borderRadius: 8,
    flex: -1,
    margin: 20,
    marginTop: 24,
  },

  date: {
    wrapperStyle: {
      ...shadows.lightAura,
      alignItems: "center",
      backgroundColor: colors.background,
      borderColor: colors.divider,
      borderRadius: 6,
      // borderWidth: 1,
      justifyContent: "space-between",
      marginLeft: 10,
      marginTop: 6,
      // height: 70,
      width: 60,
      zIndex: 2,
    },
    dayProps: {
      ellipsizeMode: "tail",
      numberOfLines: 1,
      style: {
        borderBottomLeftRadius: 4,
        borderBottomRightRadius: 4,
        color: colors.text,
        fontFamily: "Roboto",
        fontSize: 10,
        paddingBottom: 4,
        textAlign: "center",
        width: "100%",
      },
    },
    monthStyle: {
      backgroundColor: colors.error,
      borderTopLeftRadius: 4,
      borderTopRightRadius: 4,
      color: colors.offText,
      fontFamily: "Roboto",
      fontSize: 20,
      textAlign: "center",
      width: "100%",
    },
    numStyle: {
      color: colors.text,
      fontSize: 20,
      fontFamily: "DroidBold",
      //fontWeight: "bold",
      textAlign: "center",
      width: "100%",
    },
  },

  header: {
    containerStyle: {
      backgroundColor: colors.primary,
      borderTopLeftRadius: 6,
      borderTopRightRadius: 6,
      flexDirection: "row",
      // height: 84,
      justifyContent: "space-between",
    },
  },

  location: {
    courtText: {
      style: { ...typos.subTitleText, color: colors.subText },
    },
    icon: {
      ...locationIcon,
      size: 28,
      width: 70,
    },
    containerStyle: {
      borderBottomWidth: 1,
      borderColor: colors.divider,
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
      paddingBottom: 10,
      marginBottom: 5,
    },
    text: {
      style: {
        ...typos.titleText,
        color: colors.text,
      },
    },
  },

  form: {
    numberInput: {
      ...input,
      containerStyle: {
        ...input.containerStyle,
        flexDirection: "row",
        paddingHorizontal: 0,
        justifyContent: "flex-start",
        alignItems: "center",
        marginTop: 6,
      },
      errorStyle: { ...input.errorStyle, height: 0, marginVertical: 0 },
      inputStyle: {
        ...input.inputStyle,
        color: colors.text,
        fontFamily: "Roboto",
        fontSize: 16,
        maxLength: 2,
        overflow: "hidden",
        textAlign: "center",
      },
      keyboardType: "numeric",
      labelStyle: {
        ...input.labelStyle,
        borderBottomWidth: 0,
        color: colors.primary,
      },
      inputContainerStyle: {
        ...input.inputContainerStyle,
        borderColor: colors.divider,
        borderWidth: 1,
        borderBottomWidth: 1,
        borderRadius: 8,
        width: 40,
        marginLeft: 10,
      },
    },

    playersList: {
      addButtonProps: {
        ...iconButton,
        borderStyle: {
          ...iconButton.borderStyle,
          borderWidth: 0,
          borderRadius: 20,
        },
        containerStyle: {
          ...iconButton.containerStyle,
          marginLeft: 6,
          marginTop: -40,
          marginBottom: 6,
          borderRadius: 20,
        },
        iconProps: {
          ...iconButton.iconProps,
          color: colors.background,
          name: "add",
          type: "ionicons",
        },
        inactiveBackgroundColor: colors.accent,
      },

      avatarProps: {
        ...miniAvatar,
        containerStyle: {
          backgroundColor: colors.divider,
          borderWidth: 2,
          alignSelf: "center",
        },
        menuTrigger: {
          marginHorizontal: 2,
          marginVertical: 4,
          borderRadius: 20,
        },
      },
      /*
      avatarWrapperStyle: {
        justifyContent: "center",
        //backgroundColor: 10,
        // paddingLeft: 10,
        paddingTop: 2,
        width: "100%",
      },
*/
      containerStyle: { marginTop: 10 },

      header: {
        containerStyle: {
          flexDirection: "row",
          marginBottom: 6,
        },
        nbPlayerStyle: {
          ...typos.subTitleText,
          color: colors.subText,
        },
        info: {
          ...typos.subTitleText,
          color: colors.subText,
          marginTop: 12,
        },
      },

      displayNameProps: {
        style: {
          ...typos.buttonText,
          marginLeft: 16,
          marginRight: 4,
          color: colors.text,
        },
      },
      playerWrapper: {
        width: "100%",
        marginTop: 4,
        backgroundColor: colors.lighterPrimary,
        borderBottomLeftRadius: 20,
        borderTopLeftRadius: 20,
      },

      fullNameProps: {
        overflow: "hidden",
        numberOfLines: 1,
        style: { ...typos.annotation },
      },
      tribeParentNameProps: { style: { ...typos.annotation } },
      wrapperStyle: {
        flex: 1,
        flexDirection: "row",
        flexWrap: "wrap",
        justifyContent: "flex-end",
        marginTop: 10,
      },
      removeButtonStyle: {
        iconProps: { ...deleteIcon },
      },
      swipeLeftToCancelProps: {
        containerStyle: { alignSelf: "center" },
        iconProps: { ...swipeLeftIcon },
      },
      swipeStyle: {
        backgroundColor: colors.lightPrimary,
        width: 60,
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "row",
      },
    },

    rowStyle: {
      borderBottomWidth: 1,
      borderColor: colors.lightPrimary,
      alignItems: "center",
      flexDirection: "row",
      justifyContent: "space-between",
      marginTop: 8,
      paddingBottom: 7,
      width: "100%",
    },

    timeLabel: {
      paddingTop: 0,
      paddingBottom: 4,
      alignItems: "flex-end",
      //paddingLeft: 46,
    },
    timeLabelText: {
      style: { ...typos.annotation, color: colors.subText },
    },

    cancelledSessionText: {
      style: {
        ...typos.titleText,
        backgroundColor: colors.error,
        color: colors.background,
        marginBottom: 10,
        top: -10,
        padding: 4,
      },
    },

    wrapperStyle: {
      flex: 1,
      padding: 20,
      paddingTop: 0,
    },
  },

  details: {
    input: {
      multiline: true,
      containerStyle: {
        paddingHorizontal: 0,
      },
      disabledInputStyle: {
        ...input.disabledInputStyle,
        fontSize: 14,
      },
      errorStyle: { height: 0, marginVertical: 0 },
      inputContainerStyle: {
        marginTop: 2,
        padding: 0,
        backgroundColor: colors.background,
        borderBottomWidth: 0,
      },
      inputStyle: {
        ...typos.inputText,
        color: colors.text,
      },
      labelStyle: {
        ...input.labelStyle,
        borderBottomWidth: 0,
        color: colors.primary,
      },
    },
  },

  topMessage: {
    buttonSave: {
      ...button,
      titleStyle: {
        ...button.titleStyle,
        paddingHorizontal: 12,
      },
    },
    buttonClose: {
      ...iconButton,
      iconProps: {
        ...iconButton.iconProps,
        color: colors.darkAccent,
        size: 18,
        name: "close", // "account-circle-outline",
        type: "antdesign", // "ionicons", //"material-community",
      },
      inactiveBackgroundColor: colors.background,
    },

    text: {
      style: {
        ...typos.titleText,
        color: colors.darkAccent,
        fontSize: 14,
        marginHorizontal: 8,
      },
    },
    wrapperStyle: {
      alignItems: "center",
      backgroundColor: colors.background,
      borderBottomLeftRadius: 6,
      borderBottomRightRadius: 6,
      height: 52,
      flexDirection: "row",
      justifyContent: "space-between",
      paddingHorizontal: 20,
      paddingTop: 8,
    },
  },
  // *********************************************************************
  // *********************************************************************
  // *********************************************************************
  // *********************************************************************

  upperRight: {
    credits: {
      containerStyle: {
        //height: "100%",

        justifyContent: "space-between",
      },
      labelProps: {
        style: {
          ...typos.annotation,
          color: colors.offText,
          marginTop: 0,
        },
      },
      creditsProps: {
        style: {
          ...typos.numbers,
          alignSelf: "center",
          numberOfLines: 1,
          backgroundColor: colors.lighterPrimary,
          padding: 2,
          borderRadius: 30,
          color: colors.darkPrimary,
          fontSize: 14,
          textAlign: "center",
          height: 26,
          lineHeight: 22,
          width: 26,
        },
      },
      input: {
        ...input,
        containerStyle: {
          paddingHorizontal: 0,
          alignSelf: "center",
          numberOfLines: 1,
          backgroundColor: colors.lighterPrimary,
          padding: 2,
          borderRadius: 30,
          height: 26,
          width: 26,
        },
        errorStyle: { height: 0, marginVertical: 0, overflow: "hidden" },
        inputStyle: {
          ...input.inputStyle,
          ...typos.numbers,
          alignSelf: "center",
          color: colors.darkPrimary,
          maxLength: 2,
          overflow: "hidden",
          height: 26,
          lineHeight: 22,
          fontSize: 14,
          textAlign: "center",
        },
        inputContainerStyle: {
          ...input.inputContainerStyle,
          backgroundColor: colors.transparent,
          padding: 0,
          height: 22,
        },
      },
    },

    fromTo: {
      container: {
        color: colors.text,
        flexDirection: "row",
        alignItems: "flex-end",
      },
      buttonProps: {
        containerStyle: {
          borderRadius: 6,
          marginLeft: 6,
          top: 2,
        },
        titleStyle: {
          //borderBottomWidth: 0,
          //backgroundColor: colors.transparent,
          //padding: 0,
          //height: 20,
          ...typos.inputText,
          color: colors.offText,
          // overflow: "hidden",
          textAlign: "center",
          width: 60,
          height: 24,
          lineHeight: 24,
          borderColor: colors.offText,
          borderRadius: 6,
          borderWidth: 1,
        },

        inactiveBackgroundColor: colors.primary,
      },
      pickerProps: {
        style: {
          color: colors.primary,
        },
      },
      timeInput: {
        ...input,
        containerStyle: {
          marginLeft: 6,
          paddingHorizontal: 0,
          width: 72,
          height: 20,
        },
        disabledInputStyle: {
          ...input.disabledInputStyle,
          color: colors.offText,
        },
        errorStyle: { ...input.errorStyle, height: 0, marginVertical: 0 },
        inputStyle: {
          ...input.inputStyle,
          ...typos.titleText,
          fontSize: 14,
          borderColor: colors.offText,
          borderRadius: 6,
          borderWidth: 1,
          color: colors.offText,
          maxLength: 5,
          overflow: "hidden",
          textAlign: "center",
          height: 20,
        },
        inputContainerStyle: {
          ...input.inputContainerStyle,
          backgroundColor: colors.transparent,
          // marginTop: 2,
          padding: 0,
          height: 20,
        },
      },
      labelStyle: {
        ...input.labelStyle,
        color: colors.offText,
        fontSize: 12,
        borderBottomWidth: 0,
      },
      wrapperStyle: {
        alignContent: "flex-end",
        flexDirection: "row",
        justifyContent: "space-around",
        width: "100%",
      },
    },

    sessionTypePicker: {
      ...picker,
      labelContainerStyle: { height: 0 },
      labelStyle: {},
      pickerContainerStyle: {
        ...picker.pickerContainerStyle,
        borderColor: colors.offText,
      },
      style: {
        ...picker.style,
        backgroundColor: colors.primary,
        color: colors.offText,
        fontSize: 16,
        fontFamily: "Roboto",
      },
    },

    titleStyle: {
      color: colors.offText,
      fontSize: 16,
      fontFamily: "Roboto",
      textAlign: "center",
    },

    wrapperStyle: {
      flex: 1,
      justifyContent: "space-between",
      paddingHorizontal: 8,
      paddingVertical: 8,
    },
  },

  usersListMatch: {
    backgroundColor: colors.lightAccent,
  },
});
