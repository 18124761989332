export default ({
  animations,
  button,
  colors,
  iconButton,
  miniAvatar,
  picker,
  shadows,
  typos,
}) => ({
  animations,
  filtering: {
    container: {
      style: {
        backgroundColor: colors.lightPrimary,
        flexDirection: "row",
        minHeight: 40,
        justifyContent: "space-between",
        paddingVertical: 4,
        borderColor: colors.primary,
        borderBottomWidth: 1,
      },
    },
    expandButton: {
      ...button,
      titleStyle: {
        ...button.titleStyle,
        color: colors.darkPrimary,
        paddingHorizontal: 8,
      },
      containerStyle: {
        ...button.containerStyle,
        position: "absolute",
        right: 6,
        top: 6,
      },
      inactiveBackgroundColor: colors.background,
    },

    picker: {
      ...picker,
      containerStyle: {
        ...picker.containerStyle,
        marginBottom: 6,
      },
      labelStyle: {
        ...picker.labelStyle,
        borderBottomWidth: 0,
        color: colors.text,
      },
    },

    pickerWrapperStyle: {
      paddingLeft: 10,
      paddingRight: 60, // 42,
      alignItems: "center",
    },

    textWrapperStyle: {
      alignItems: "center",
      flexDirection: "row",
      overflow: "hidden",
      paddingLeft: 10,
      paddingRight: 60, //42,
      width: "100%",
      marginVertical: 2,
    },
    textTitleProps: {
      numberOfLines: 1,
      style: {
        ...typos.subTitleText,
        color: colors.text,
        marginLeft: 10,
      },
    },
    textValueProps: {
      numberOfLines: 1,
      style: {
        ...typos.subTitleText,
        color: colors.text,
        fontFamily: "RobotoMedium",
        marginLeft: 10,
        marginRight: 10,
      },
    },
    textValuePropsSelected: {
      numberOfLines: 1,
      style: {
        ...typos.titleText,
        backgroundColor: colors.darkPrimary,
        color: colors.background,
        fontFamily: "RobotoMedium",
        marginLeft: 10,
        marginRight: 10,
        paddingHorizontal: 6,
      },
    },
  },

  monthView: {
    containerProps: {
      style: {
        // alignSelf: "center",
        flex: 1,
        padding: 10,
        width: "100%",
      },
    },
    dayProps: {
      container: {
        style: {
          flexDirection: "row",
          marginVertical: 7,
          backgroundColor: colors.lighterTranslucent,
          //borderBottomWidth: 1,
          //borderTopWidth: 1,
          borderColor: colors.divider,
          justifyContent: "center",
          paddingVertical: 8,
        },
      },
      separator: {
        style: {
          borderColor: colors.primary,
          borderBottomWidth: 0,
          flexGrow: 1,
          height: 12,
          marginHorizontal: 10,
        },
      },
      textStyle: {
        textAlign: "center",
        color: colors.offText,
        fontSize: 16,
        fontFamily: "RobotoItalic",
      },
    },
    todayProps: {
      container: {
        style: {
          flexDirection: "row",
          marginVertical: 7,
          backgroundColor: colors.lightTranslucent,
          //borderBottomWidth: 1,
          //borderTopWidth: 1,
          //borderColor: colors.darkPrimary,
          justifyContent: "center",
          paddingVertical: 8,
        },
      },
      textStyle: {
        color: colors.lightPrimary,
        fontSize: 18,
        fontFamily: "RobotoItalic",
      },
      separator: {
        style: {
          borderColor: colors.lightPrimary,
          borderBottomWidth: 2,
          flexGrow: 1,
          height: 12,
          marginHorizontal: 10,
        },
      },
    },
    weekProps: {
      container: {
        style: {
          flexDirection: "row",
        },
      },
      separator: {
        style: {
          borderColor: colors.lightText,
          borderBottomWidth: 1,
          flexGrow: 1,
          height: 12,
          marginRight: 10,
        },
      },
      textStyle: {
        color: colors.lightText,
        fontSize: 14,
        fontFamily: "RobotoItalic",
        paddingHorizontal: 5,
      },
    },
    wrapperStyle: {
      // backgroundColor: "red", //colors.background,
      flex: 1,
      width: "100%",
    },
  },

  noBooking: {
    ...typos.titleText,
    color: colors.lightText,
    textAlign: "center",
    padding: 20,
  },

  headingBar: {
    container: {
      backgroundColor: colors.primary,
      borderColor: colors.darkPrimary,
      borderTopWidth: 1,
      borderBottomWidth: 1,
      flexDirection: "row",
      height: 28,
      //justifyContent: "center",
      paddingHorizontal: 12,
      width: "100%",
    },
    textStyle: {
      ...typos.titleText,
      alignSelf: "center",
      color: colors.offText,
      //fontSize: 14,
      // paddingTop: 10,
    },
  },

  newSession: {
    ...button,
    containerStyle: {
      ...button.containerStyle,
      //flex: 1,
      marginHorizontal: 6,
    },
    titleStyle: {
      ...button.titleStyle,
      color: colors.primary,
      fontSize: 14,
      paddingHorizontal: 10,
    },
    inactiveBackgroundColor: colors.background,
    activeBackgroundColor: colors.subText,
  },

  selectMonthBarStyle: {
    alignItems: "center",
    backgroundColor: colors.background,
    borderBottomWidth: 1,
    borderColor: colors.darkPrimary,
    flexDirection: "row",
    // justifyContent: "space-evenly",
    height: 40,
  },

  selectMonth: {
    container: {
      style: {
        alignItems: "center",
        flexDirection: "row",
        flex: 1,
        justifyContent: "space-around",
      },
    },
    leftButton: {
      ...iconButton.iconProps,
      containerStyle: {},
      color: colors.darkPrimary,
      name: "left",
      size: 18,
      type: "antdesign",
    },
    rightButton: {
      ...iconButton.iconProps,
      containerStyle: {},
      color: colors.darkPrimary,
      name: "right",
      size: 18,
      type: "antdesign",
    },
    monthYearText: {
      style: {
        ...typos.titleText,
        color: colors.primary, //colors.onCard,
        fontSize: 18,
      },
    },
  },

  sessions: {
    containerStyle: {
      //backgroundColor: "#fff3e0", //colors.lightAccent, //colors.surface,
      // borderColor: colors.subText,
      // borderRadius: 10,
      // borderWidth: 1,
      marginHorizontal: 1,
      paddingHorizontal: 1,
      marginBottom: 12,
    },
    locationProps: {
      ellipsizeMode: "tail",
      numberOfLines: 1,
      style: {
        color: colors.offText,
        fontSize: 16,
        fontFamily: "RobotoLight", // robotoLight
        marginLeft: 4,
        marginBottom: 1,
        marginTop: 3,
      },
    },

    session: {
      ageGroup: {
        wrapperStyle: {
          defaultBackgroundColor: colors.divider,

          alignItems: "center",
          borderRadius: 8,
          borderWidth: 2,
          borderColor: colors.background,
          justifyContent: "center",

          marginTop: 1,
          marginBottom: 4,
          height: 24,
          //minHeight: 22,
          minWidth: 32,
        },
        textProps: {
          style: {
            ...typos.annotation,
            color: colors.offText,
          },
        },
      },
      avatarProps: {
        ...miniAvatar,
        containerStyle: {
          ...miniAvatar.containerStyle,
          marginHorizontal: 2,
        },
      },
      cancelledSession: {
        backgroundColor: colors.cancelled,
      },
      category: {
        wrapperStyle: {
          borderRadius: 12,
          flexShrink: 1,
          marginBottom: 4,
          paddingHorizontal: 4,
          paddingTop: 1,
          height: 24,
        },
        textProps: {
          ellipsizeMode: "tail",
          numberOfLines: 1,
          style: {
            color: colors.lightTranslucent,
            fontSize: 12,
            fontFamily: "DroidItalic",
            marginTop: 5, //2,
          },
        },
      },
      coachAvatar: {
        ...miniAvatar,
      },
      coachLabel: {
        ellipsizeMode: "tail",
        numberOfLines: 1,
        style: {
          ...typos.annotation,
          textAlign: "center",
          width: 30,
        },
      },
      containerStyle: {
        backgroundColor: colors.background, //colors.surface,
        borderColor: colors.offText,
        borderRadius: 8,
        borderWidth: 1,
        flexDirection: "column",
        // flex: 1,
        marginHorizontal: 2,
        marginBottom: 3,
        //minHeight: 28,
        height: 78,
        paddingHorizontal: 3,
        ...shadows.lightAura,
        shadowColor: colors.background,
        //shadowOpacity: 0.3,
        //shadowRadius: 6,
      },
      courtProps: {
        ellipsizeMode: "tail",
        numberOfLines: 1,
        style: {
          color: colors.offText,
          fontSize: 13,
          fontFamily: "RobotoItalic", // robotoLight
          height: 15,
          marginLeft: 10,
          marginBottom: 2,
          marginTop: -2,
        },
      },
      emptySlotStyle: {
        marginHorizontal: 2,
        marginBottom: 3,
        height: 80,
      },
      pastSessionStyle: {
        backgroundColor: colors.divider,
      },
      sessionFull: {
        backgroundColor: colors.lighterAccent,
      },
      message: {
        ellipsizeMode: "tail",
        numberOfLines: 1,
        style: {
          ...typos.annotation,
          textAlign: "right",
          flex: 1,
          marginHorizontal: 4,
        },
      },
      sessionTypeProps: {
        numberOfLines: 1,
        style: {
          flexBasis: "auto",
          flexShrink: 1,
          color: colors.text,
          fontFamily: "RobotoMedium",
          fontSize: 14,
          lineHeight: 24,
          height: 24,
          marginTop: 2,
          marginLeft: 12,
        },
      },
      timeProps: {
        style: {
          color: colors.text,
          fontFamily: "Roboto",
          fontSize: 14,
          lineHeight: 24,
          height: 24,
          marginTop: 2,
          top: 0,
        },
      },
    },

    session2: {
      ageGroup: {
        wrapperStyle: {
          defaultBackgroundColor: colors.divider,
          borderRadius: 8,
          borderColor: colors.background,
        },
        textProps: {
          numberOfLines: 1,
          style: {
            ...typos.annotation,
            color: colors.offText,
            fontSize: 12,
            paddingVertical: 4,
            paddingHorizontal: 6,
          },
        },
      },
      avatarProps: {
        ...miniAvatar,
        containerStyle: {
          ...miniAvatar.containerStyle,
          marginHorizontal: 2,
        },
      },
      cancelledSession: {
        backgroundColor: colors.cancelled,
      },
      category: {
        wrapperStyle: {
          borderRadius: 12,
          flexShrink: 1,
          marginBottom: 4,
          paddingHorizontal: 4,
          paddingTop: 1,
          height: 24,
        },
        textProps: {
          ellipsizeMode: "tail",
          numberOfLines: 1,
          style: {
            color: colors.lightTranslucent,
            fontSize: 12,
            fontFamily: "DroidItalic",
            marginTop: 5, //2,
          },
        },
      },
      containerStyle: {
        backgroundColor: colors.background, //colors.surface,
        borderColor: colors.offText,
        borderRadius: 6,
        flexDirection: "column",
        marginBottom: 3,
        height: 92,
        overflow: "hidden",
        paddingHorizontal: 3,

        // !!!!! CAREFUL change in the following properties
        //    should be reflected in Session3.js adding to 
        //    playersWidth value.
        borderWidth: 1,
        marginHorizontal: 2,
        paddingVertical: 3,
        //...shadows.lightAura,
        //shadowColor: colors.background,
      },
      courtProps: {
        ellipsizeMode: "tail",
        numberOfLines: 1,
        style: {
          color: colors.offText,
          fontSize: 13,
          fontFamily: "RobotoItalic", // robotoLight
          height: 15,
          marginLeft: 6,
          marginTop: 2,
          marginBottom: 4,
        },
      },
      emptySlotStyle: {
        marginHorizontal: 2,
        marginBottom: 3,
        height: 92,
      },
      pastSessionStyle: {
        backgroundColor: colors.divider,
      },
      sessionFull: {
        backgroundColor: colors.lighterAccent,
      },
      message: {
        //ellipsizeMode: "tail",
        numberOfLines: 1,
        style: {
          ...typos.annotation,
          fontFamily: "RobotoMedium",
          fontSize: 11,
          textAlign: "right",
          flex: 1,
          marginHorizontal: 4,
        },
      },
      sessionTypeProps: {
        numberOfLines: 1,
        style: {
          color: colors.text,
          flex: 1,
          fontFamily: "RobotoMedium",
          fontSize: 14,
          marginLeft: 2,
        },
      },
      timeProps: {
        numberOfLines: 1,
        style: {
          color: colors.text,

          fontFamily: "RobotoMedium",
          fontSize: 14,
        },
      },
    },
  },
});
