export default Object.freeze({
  ACCOUNT_DETAILS: "AccountDetails",
  ADMIN_HOME: "AdminHome",
  ADMIN_NAVIGATOR: "AdminNavigator",
  ADMIN_USERS_LIST: "AdminUsersList",
  ADMIN_ACCOUNT_DETAILS: "AdminAccountDetails",
  AGENDA: "Agenda",
  BOOKING: "Booking",
  BUY_CREDITS: "BuyCredits",
  DATA_EDITOR: "DataEditor",
  DATA_LIST: "DataList",
  HOME: "Home",
  HOME_NAVIGATOR: "HomeNavigator",
  HITPAY_PAYMENT: "HitPayPayment",
  INFORMATION: "Information",
  SCHEDULE: "Schedule",
  SESSION_DETAILS: "SessionDetails",
  USERS_LIST: "UsersList",
  // TAB: "Tab",
  //  TEST: "Test",
  WELCOME: "Welcome",
});
