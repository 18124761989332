// Libraries
import React, { useCallback, useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigation } from "@react-navigation/native";
import dayjs from "dayjs";
dayjs.extend(require("dayjs/plugin/isoWeek"));
dayjs.extend(require("dayjs/plugin/customParseFormat"));

// UI components
import { Text, View } from "react-native";
import CalendarView from "./components/CalendarView";
import Filter from "./components/Filter";
import Loading from "../Loading";

// App data
import routes from "../../navigation/routes";
import { LanguageContext, StylesContext } from "../../data/contexts";

// API
import sessionsAPI from "../../data/sessions";

// Component: Booking
export default function Booking() {
  const [loading, setLoading] = useState(true);
  const [filter, setFilter] = useState({});
  const [preparedSessions, setPreparedSessions] = useState(null);
  const [lastWeekLoaded, setLastWeekLoaded] = useState(null);
  const navigation = useNavigation();
  const userData = useSelector((state) => state.user);
  const lang = useContext(LanguageContext);
  const { Agenda: props } = useContext(StylesContext);

  useEffect(() => {
    const user = userData.details;
    // initialize filters
    if (user) {
      const tribeNamesList = [user.uid];
      if (user.dependants)
        user.dependants.forEach((member) => {
          tribeNamesList.push(member.uid);
        });
      setFilter({
        ...filter,
        cancelled: false,
      });
    }
  }, [userData]);

  useEffect(() => {
    if (!lang) return;
    (async () => {
      refreshData();
    })();
  }, [filter, lang]);

  useEffect(() => {
    if (preparedSessions) setLoading(false);
  }, [preparedSessions]);

  const refreshData = useCallback(async () => {
    if (!lang || !userData) return;
    setLoading(true);
    const today = dayjs();
    const todayNumber = parseInt(today.format("YYMMDD"));
    let newSessions = {};

    // load first week and keep only data from today
    let loadedSessions = await sessionsAPI.loadSessionData(today);
    for (const day in loadedSessions) {
      if (parseInt(day) >= todayNumber) newSessions[day] = loadedSessions[day];
    }
    // load Second week
    const nextWeek = today.clone().add(1, "week");
    loadedSessions = await sessionsAPI.loadSessionData(nextWeek);
    newSessions = { ...newSessions, ...loadedSessions };
    // setPreparedSession;
    setPreparedSessions(prepareSessions(newSessions, filter, lang, userData));

    // reset lastWeekLoaded
    setLastWeekLoaded(nextWeek);
  }, [filter, lang, userData]);

  const loadMoreData = useCallback(async () => {
    if (!lang || !userData) return;
    const nextWeek = lastWeekLoaded.clone().add(1, "week");
    const loadedSessions = await sessionsAPI.loadSessionData(nextWeek);
    preparedSessions.push(
      ...prepareSessions(loadedSessions, filter, lang, userData)
    );
    setLastWeekLoaded(nextWeek);
  }, [lastWeekLoaded, preparedSessions, lang, filter, userData]);

  const onSelectSession = useCallback((session) => {
    sessionsAPI.loadSessionData(session.date);
    navigation.navigate(routes.SESSION_DETAILS, {
      data: { ...session },
      isNew: false,
    });
  }, []);

  // Rendering
  if (
    !userData ||
    !userData.details ||
    !userData.avatars ||
    !userData.UIdata ||
    !userData.rules ||
    !lang
  ) {
    return null;
  } else {
    return (
      <>
        <View style={{ flex: 1 }}>
          <View style={props.headingBar.container}>
            <Text style={props.headingBar.textStyle}>
              {lang.BOOK_SESSION_TITLE}
            </Text>
          </View>

          <Filter
            props={props.filtering}
            lang={lang}
            UIdata={userData.UIdata}
            filter={filter}
            setFilter={setFilter}
          />

          <View style={{ flex: 1 }}>
            {loading ? (
              <Loading />
            ) : (
              <View style={{ flex: 1, paddingHorizontal: 6 }}>
                <CalendarView
                  lang={lang}
                  loadMoreData={loadMoreData}
                  refreshData={refreshData}
                  triggerReRenderData={lastWeekLoaded}
                  onSelectSession={onSelectSession}
                  refreshing={loading}
                  sessionsProps={props.sessions}
                  dayData={preparedSessions}
                  userData={userData}
                  {...props.monthView}
                />
              </View>
            )}
          </View>
        </View>
      </>
    );
  }
}

const prepareSessions = (sessions, filter, lang, userData) => {
  const preparedSessions = sessionsAPI.sortAndFillSessions(
    sessionsAPI.filterSessions(sessions, filter),
    null,
    null,
    false
  );
  let readyData = [];
  for (const day in preparedSessions) {
    readyData.push(prepareDay(preparedSessions[day], lang, userData));
  }
  return readyData;
};

const prepareDay = (dayData, lang, userData) => {
  let sessions = dayData.data;

  const newSlots = new Set();
  for (var sessionsInLocation in sessions) {
    for (var sessionsInCourts in sessions[sessionsInLocation]) {
      for (var sessionHour in sessions[sessionsInLocation][sessionsInCourts]) {
        newSlots.add(sessionHour);
        const session =
          sessions[sessionsInLocation][sessionsInCourts][sessionHour];
        const spots_left =
          session?.max_players - (session.players || []).length;
        session.isPast = sessionsAPI.isPast(session.date + session.time);
        session.formattedTime = sessionsAPI.timeBackToFront(session.time);
        session.formattedSessionType =
          userData.UIdata?.session_types[session.session_type]?.short_label ||
          session.session_type;
        session.ui_age_group =
          userData.UIdata["age_groups"].find(
            (el) => el.label === session.age_group
          ) || {};
        session.ui_category =
          userData.UIdata["categories"].find(
            (el) => el.label === session.category
          ) || {};
        if (
          !session.date ||
          !session.time ||
          session.cancelled ||
          session.isPast
        ) {
          session.message = lang.CLOSED_SESSION;
          session.mayAdd = false;
        } else if (session.max_players && spots_left > 0) {
          if (spots_left == 1) session.message = lang.ONE_SPOT_LEFT;
          else session.message = spots_left + lang.SEVERAL_SPOT_LEFT;
          session.mayAdd = true;
        } else {
          session.messaeg = lang.NO_SPOT_LEFT;
          session.mayAdd = false;
        }
      }
    }
  }

  const sortedLocations = Object.keys(sessions).sort((first, second) =>
    first.toUpperCase() > second.toUpperCase() ? 1 : -1
  );

  let sortedSites = [];
  sortedLocations.forEach((location) => {
    const sortedCourts = Object.keys(sessions[location]).sort((first, second) =>
      first.toUpperCase() > second.toUpperCase() ? 1 : -1
    );
    sortedSites.push({ location, courts: sortedCourts });
  });

  return {
    date: dayData.date,
    sessions,
    sortedSites,
    slots: Array.from(newSlots).sort(),
  };
};
