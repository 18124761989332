// Libraries
import React, { useCallback, useContext, useState } from "react";
import { useSelector } from "react-redux";
import dayjs from "dayjs";
dayjs.extend(require("dayjs/plugin/isoWeek"));
import { useNavigation } from "@react-navigation/native";

// UI components
import { Text, View } from "react-native";
import { MonthSelector, OverlayBox } from "../../components";
import MonthViewSchedule from "./components/MonthViewSchedule";
import Filter from "./components/Filter";
import Loading from "../Loading";

// App data
import routes from "../../navigation/routes";
import { LanguageContext, StylesContext } from "../../data/contexts";

// API
import sessionsAPI from "../../data/sessions";

// Component: Agenda
export default function Agenda({ title }) {
  const [message, setMessage] = useState(null);
  const [month, setMonth] = useState(null);
  const [loading, setLoading] = useState(true);
  const [clipboard, setClipboard] = useState({});
  const [filter, setFilter] = useState({});
  const [overlayMessage, setOverlayMessage] = useState({ visible: false });
  const navigation = useNavigation();
  const userData = useSelector((state) => state.user);
  const sessions = useSelector((state) => {
    return (state.sessions || {})[month?.format("YYYYMM")]; // [month ? month.format("YYYYMM") : null] || {};
  });

  const lang = useContext(LanguageContext);
  const { Agenda: props } = useContext(StylesContext);

  const loadMonth = useCallback((month) => {
    sessionsAPI
      .loadMonthSessions(month)
      .then(() => {
        setLoading(false);
        setMonth(month);
      })
      .catch((e) => {
        console.warn("Loading month data failed unexpectedly. ", e);
        setLoading(false);
      });
  }, []);

  const onNewSession = useCallback((date) => {
    navigation.navigate(routes.SESSION_DETAILS, {
      data: { date: date.format("YYMMDD") },
      isNew: true,
    });
  }, []);

  const onWeekCopy = useCallback(
    (firstDayOfWeek) => {
      const copiedSessions = [];
      for (
        let day = 0; // firstDayOfWeek.clone();
        day < 7; // day.add(7, "day");
        day++ // = day.add(1, "day")
      ) {
        const date = firstDayOfWeek.add(day, "day");
        let daySessions = {};
        daySessions[date.format("YYMMDD")] = sessions[date.format("YYMMDD")];
        copiedSessions.push(...flattenSessions(daySessions));
      }
      setMessage(
        "week " +
          firstDayOfWeek.isoWeek() +
          " of " +
          firstDayOfWeek.year() +
          " copied"
      );
      setClipboard({
        type: "week",
        sessionDate: firstDayOfWeek.format("YYMMDD"),
        content: copiedSessions,
      });
    },
    [sessions]
  );

  const onDayCopy = useCallback((sessionDate, _sessions) => {
    let daySessions = {};
    daySessions[sessionDate.format("YYMMDD")] = _sessions;
    const copiedSessions = flattenSessions(daySessions);
    setMessage("day " + sessionDate.format("DD MMM YYYY") + " copied");
    setClipboard({
      type: "day",
      sessionDate: sessionDate.format("YYMMDD"),
      content: copiedSessions,
    });
  }, []);

  /*
  const onNewBooking = useCallback(() => {
    navigation.navigate(routes.BOOKING);
  }, []);
*/

  const onSelectSession = useCallback((session) => {
    sessionsAPI.loadSessionData(session.date);
    navigation.navigate(routes.SESSION_DETAILS, {
      data: { ...session },
      isNew: false,
    });
  }, []);

  const onSessionCopy = useCallback((session) => {
    const copiedSessions = [];
    copiedSessions.push(copySession(session));
    setMessage("session copied");
    setClipboard({
      content: copiedSessions,
      sessionDate: session.date,
      type: "session",
    });
  }, []);

  // Rendering
  if (!userData || !userData.UIdata || !userData.details || !userData.rules) {
    return <Loading />;
  } else {
    return (
      <>
        <View style={{ flex: 1 }}>
          <View style={props.headingBar.container}>
            <Text style={props.headingBar.textStyle}>{title}</Text>
          </View>
          <Filter
            props={props.filtering}
            lang={lang}
            UIdata={userData.UIdata}
            filter={filter}
            setFilter={setFilter}
          />
          <View style={{ flex: 1 }}>
            <View style={props.selectMonthBarStyle}>
              <MonthSelector
                containerProps={props.selectMonth.container}
                leftButtonProps={props.selectMonth.leftButton}
                monthList={lang.MONTHS_LONG}
                onChange={(selectedMonth) => {
                  setLoading(true);
                  loadMonth(selectedMonth);
                }}
                rightButtonProps={props.selectMonth.rightButton}
                titleProps={props.selectMonth.monthYearText}
              />
            </View>
            {loading ? (
              <Loading />
            ) : (
              <View style={{ flex: 1 }}>
                {message ? (
                  <View
                    style={{
                      backgroundColor: "rgba(0, 0, 0, 0.7)",
                      flexDirection: "row",
                      justifyContent: "center",
                      overflow: "hidden",
                      marginLeft: "15%",
                      paddingHorizontal: 10,
                      paddingVertical: 6,
                      position: "absolute",
                      width: "70%",
                      zIndex: 1,
                    }}
                  >
                    <Text style={{ color: "white", right: 0 }}>{message}</Text>
                  </View>
                ) : null}
                <MonthViewSchedule
                  clipboard={clipboard}
                  isSessionEditor={userData.rules.ROLE_TO_EDIT_SESSION}
                  isPlayersEditor={userData.rules.ROLE_TO_EDIT_PLAYER}
                  lang={lang}
                  month={month}
                  noBookingText={lang.NO_BOOKING}
                  noBookingStyle={props.noBooking}
                  onNewSession={onNewSession}
                  onDayCopy={onDayCopy}
                  onSelectSession={onSelectSession}
                  onSessionCopy={onSessionCopy}
                  onWeekCopy={onWeekCopy}
                  onPaste={(targetDay) =>
                    pasteSessions(
                      clipboard.content,
                      clipboard.type,
                      targetDay,
                      sessions,
                      setMessage,
                      props,
                      setOverlayMessage
                    )
                  }
                  sessionsProps={props.sessions}
                  sessions={filterSessions(sessions, filter)}
                  userData={userData}
                  weekLocalized={lang.WEEK}
                  {...props.monthView}
                />
              </View>
            )}
          </View>
        </View>
        <OverlayBox {...overlayMessage} />
      </>
    );
  }
}

const copySession = (session) => {
  const newSession = {
    ...session,
    cancelled: false,
    players: [],
    waitlist: [],
  };
  delete newSession.docID;
  delete newSession.validated;

  return newSession;
};

const pasteSessions = (
  sessionsToPaste,
  type,
  targetDay,
  sessions = {},
  setMessage,
  props,
  setOverlayMessage
) => {
  let newTargetDate;
  let newSessionDay;
  // let promises = [];
  let pastingSessions = [];

  setOverlayMessage({ ...props.animations.WORKING_ON_IT, visible: true });

  sessionsToPaste.forEach((session) => {
    if (type === "week") {
      let sessionDay;
      sessionDay = sessionsAPI.sessionDateToDayjs(session.date).day();
      if (sessionDay == 0) sessionDay = 7;
      newSessionDay = targetDay.clone().day(sessionDay);
    } else {
      newSessionDay = targetDay.clone();
    }
    newTargetDate = newSessionDay.format("YYMMDD");

    if (
      !sessionsAPI.isSessionOverlap(
        session,
        (((sessions[newSessionDay.format("YYYYMM")] || {})[newTargetDate] ||
          {})[session.location] || {})[session.court || "_"]
      )
    ) {
      //promises.push(
      //  sessionsAPI.newSession({ ...session, date: newTargetDate })
      //);
      pastingSessions.push({ ...session, date: newTargetDate });
    } else setMessage("Some paste failed due to Overlapping sessions.");
  });

  // if (promises.length) {
  //  Promise.all(promises)
  if (pastingSessions.length) {
    sessionsAPI
      .pasteSessions(pastingSessions)
      .then(() => {
        setOverlayMessage({
          ...props.animations.CHECKMARK,
          callback: () => setOverlayMessage({ visible: false }),
          time: 1000,
          visible: true,
        });
      })
      .catch((e) => {
        console.warn("Session paste failed. ", e);
        setOverlayMessage({
          ...props.animations.ERROR,
          callback: () => setOverlayMessage({ visible: false }),
          message: "Paste failed.",
          time: 1000,
          visible: true,
        });
      });
  } else setOverlayMessage({ visible: false });
};

const flattenSessions = (sessions) => {
  const flatSessions = [];
  // Object.entries(sessions || {}).forEach(([month, obj0]) => {
  Object.entries(sessions || {}).forEach(([date, obj1]) => {
    Object.entries(obj1 || {}).forEach(([location, obj2]) => {
      Object.entries(obj2 || {}).forEach(([court, obj3]) => {
        Object.entries(obj3 || {}).forEach(([time, session]) => {
          if (session) flatSessions.push({ ...copySession(session) });
        });
      });
    });
  });
  // });
  return flatSessions;
};

const filterSessions = (sessions = {}, filter) => {
  try {
    const filterKeys = Object.keys(filter || {});
    const filteredSessions = {};

    if (filterKeys.length) {
      Object.keys(sessions).forEach((day) => {
        Object.keys(sessions[day]).forEach((location) => {
          Object.keys(sessions[day][location]).forEach((court) => {
            Object.keys(sessions[day][location][court]).forEach((time) => {
              if (
                filterKeys.reduce((result, filterKey) => {
                  if (!result) return false;
                  if (filterKey == "users" && filter["users"]) {
                    let players = [
                      ...sessions[day][location][court][time]["players"],
                    ];
                    if (sessions[day][location][court][time]["coach"])
                      players.push({
                        uid: sessions[day][location][court][time]["coach"],
                      });
                    if (!players.length) return false;
                    return players.some((player) =>
                      filter["users"].includes(player.uid)
                    );
                  } else {
                    if (
                      ![null, undefined, ""].includes(filter[filterKey]) &&
                      sessions[day][location][court][time][filterKey] &&
                      filter[filterKey] !==
                        sessions[day][location][court][time][filterKey]
                    )
                      return false;
                  }

                  return true;
                }, true)
              ) {
                filteredSessions[day] = filteredSessions[day] || {};
                filteredSessions[day][location] =
                  filteredSessions[day][location] || {};
                filteredSessions[day][location][court] =
                  filteredSessions[day][location][court] || {};
                filteredSessions[day][location][court][time] = {
                  ...sessions[day][location][court][time],
                };
              }
            });
          });
        });
      });
    } else return sessions;
    return filteredSessions;
  } catch (err) {
    console.warn("Filtering session failed. ", err);
    return {};
  }
};

/*
        {scheduling && !granted.ROLE_TO_EDIT_SESSION ? (
          <View style={{ margin: 10, alignContent: "flex-end", width: "100%" }}>
            <Credits editing={true} />
          </View>
        ) : null}
*/
